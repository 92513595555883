<template>
  <div>
    <span v-if="column.action === 'view-quote'">
      <router-link :to="{ path: quoteViewPath() }" class="action-link">
        <v-icon color="primary">remove_red_eye</v-icon>
      </router-link>
    </span>
    <span v-if="column.action === 'view-reservation'">
      <router-link :to="{ path: reservationViewPath() }" class="action-link">
        <v-icon color="primary">remove_red_eye</v-icon>
      </router-link>
    </span>
    <span v-if="column.action === 'view-customer'">
      <router-link :to="{ name: 'customers.view', params: { id: row.userId } }">
        <v-icon color="primary">remove_red_eye</v-icon>
      </router-link>
    </span>
  </div>
</template>

<script>
import { buildReservationPath } from '@/utils/reservationPathBuilder'

export default {
  props: {
    row: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
  },
  methods: {
    quoteViewPath() {
      return `/view/${this.row.quoteId}`
    },
    reservationViewPath() {
      return buildReservationPath(this.row)
    }
  },
}
</script>

<style lang="scss">
.action-links a,
.action-link {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
}
</style>
