<template>
  <v-toolbar color="transparent" class="the-topbar">
    <!-- add `fixed` to make sticky -->
    <v-layout
      row
      align-content-center
      justify-space-between
      style="margin-left: -4px"
    >
      <v-btn
        flat
        icon
        class="nav-sidebar-toggle"
        @click="$emit('sidebar-toggled')"
      >
        <CRIcon icon-name="menu" view-box="0 0 25 25" :width="25" :height="25">
          menu
        </CRIcon>
      </v-btn>

      <router-link :to="{ name: 'home' }" replace class="nav-logo">
        <img
          src="@/assets/images/logo-coachrail.svg"
          style="
            position: relative;
            left: -10px;
            display: block;
            width: 130px;
            margin: 12px auto;
          "
        />
      </router-link>

      <v-spacer class="grow" />

      <v-btn icon class="search-ico-cont" @click="toggleModal">
        <CRIcon
          :width="26"
          :height="26"
          margin="2px 0 0 0"
          class="search-icon-top"
          icon-name="search"
        >
          search_large
        </CRIcon>
      </v-btn>

      <SearchModal v-if="dialog" :close="toggleModal" :dialog="dialog" />

      <v-menu v-if="addButtonShowsMenu" bottom transition="slide-x-transition">
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <CRIcon class="add-circle" icon-name="add-new" margin="0 0 1px 0">
              new_quote
            </CRIcon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile @click="addNewQuote">
            <v-list-tile-title>
              <a><span class="action-text">Add New Quote</span></a>
            </v-list-tile-title>
          </v-list-tile>

          <v-list-tile @click="$router.push({ name: 'contracts.add' })">
            <v-list-tile-title>
              <a><span class="action-text">Add New Contract Quote</span></a>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="isShuttleCreateQuoteEnabled && canCreateShuttleQuotes"
            @click="
              $router.push({ name: 'quotes.new', params: { mode: 'add' } })
            "
          >
            <v-list-tile-title>
              <a><span class="action-text">Add New Route Quote</span></a>
            </v-list-tile-title>
          </v-list-tile>

          <v-list-tile @click="() => $router.push({ name: 'tickets.add' })">
            <v-list-tile-title>
              <a><span class="action-text">Add New Ticket</span></a>
            </v-list-tile-title>
          </v-list-tile>

          <v-list-tile @click="() => $router.push({ name: 'customers.add' })">
            <v-list-tile-title>
              <a><span class="action-text">Add New Customer</span></a>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>

      <v-btn
        v-if="addButtonCreatesLead"
        icon
        @click="$router.push({ name: 'leads.add' })"
      >
        <CRIcon class="add-circle" icon-name="add-new">new_quote</CRIcon>
      </v-btn>

      <v-btn
        v-if="addButtonCreatesQuote"
        icon
        @click="$router.push({ name: 'quotes.add' })"
      >
        <CRIcon class="add-circle" icon-name="add-new">new_quote</CRIcon>
      </v-btn>

      <v-menu bottom transition="slide-x-transition">
        <template #activator="{ on }">
          <v-btn id="topbar-button-profile" icon v-on="on">
            <v-avatar size="35">
              <img
                v-if="avatarLink"
                :src="avatarLink"
                :class="{ 'active-session': hasActiveTransformationSession }"
              />
              <CRIcon
                v-else
                :class="{ 'active-session': hasActiveTransformationSession }"
                icon-name="my-account"
                :width="32"
                :height="32"
              >
                my_account
              </CRIcon>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile
            id="topbar-menu-my-profile-link"
            @click="
              () =>
                $router.push({
                  name: 'users.edit',
                  params: { id: currentUser.userId },
                })
            "
          >
            <v-list-tile-title>
              <a>
                <span class="action-text">My Profile</span>
              </a>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            id="topbar-menu-my-sales-dashboard-link"
            @click="() => $router.push({ name: 'my-sales-dashboard' })"
          >
            <v-list-tile-title>
              <a><span class="action-text">My Sales Dashboard</span></a>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            id="topbar-menu-change-password-link"
            @click="() => $router.push({ name: 'change-password' })"
          >
            <v-list-tile-title>
              <a>
                <span class="action-text">Change Password</span>
              </a>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            id="topbar-menu-change-timezone-link"
            @click="() => $router.push({ name: 'change-timezone' })"
          >
            <v-list-tile-title>
              <a>
                <span class="action-text">Change Time Zone</span>
              </a>
            </v-list-tile-title>
          </v-list-tile>

          <v-list-tile id="topbar-menu-logout-link" @click="logOut">
            <v-list-tile-title>
              <a>
                <span class="action-text">Logout</span>
              </a>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="canTransformCompany && !hasActiveTransformationSession"
            id="topbar-menu-login-as-provider-link"
            @click="showTransformCompanyModal = true"
          >
            <v-list-tile-title>
              <a>
                <span class="action-text">Login As Provider</span>
              </a>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="hasActiveTransformationSession"
            id="topbar-menu-back-to-admin-link"
            @click="backToAdmin"
          >
            <v-list-tile-title>
              <a>
                <span class="action-text">Back To Admin</span>
              </a>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
      <CompanySelectionDialog
        v-if="showTransformCompanyModal"
        @close="showTransformCompanyModal = false"
      />
    </v-layout>
  </v-toolbar>
</template>

<script>
import SearchModal from '@/components/searchModal.vue'
import CompanySelectionDialog from '@/components/CompanySelectionDialog.vue'
import transformationSession from '@/services/transformationSession'
import { mapActions, mapGetters } from 'vuex'
import { baseUrl } from '@/utils/env'
import { authComputed } from '@/state/helpers'

export default {
  components: {
    SearchModal,
    CompanySelectionDialog,
  },
  props: {
    search: {
      type: Boolean,
      default: false,
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      avatarLink: null,
      window,
      currentCompanyId: null,
      // isSearchOpen: false,
      searchText: '',
      isSidebarOpen: this.sidebar,
      showTransformCompanyModal: false,
      hasActiveTransformationSession: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      isShuttleCreateQuoteEnabled: 'featureToggles/isShuttleCreateQuoteEnabled',
    }),
    canCreateShuttleQuotes() {
      return this.$store.getters['auth/hasPermission']('canCreateShuttleQuotes')
    },
    isSearchTextEntered() {
      return this.searchText.length > 0
    },
    companyId() {
      return this.currentUser.companyId
    },
    canTransformCompany() {
      const roles = this.currentUserProfile?.roles || []
      return !!roles.find(
        (role) => role.roleName === 'can_transform_all_companies'
      )
    },
    addButtonShowsMenu() {
      return !(this.isSDR || this.isSDRManager || this.isRA) || this.isMmeSales
    },
    addButtonCreatesLead() {
      return (this.isSDR || this.isSDRManager) && !this.isMmeSales
    },
    addButtonCreatesQuote() {
      return this.isRA && !this.isMmeSales
    },
  },
  watch: {
    isSearchTextEntered: {
      handler(newValue) {
        this.$emit(newValue ? 'search-activate' : 'search-deactivate')
      },
    },
    sidebar: {
      handler(newValue) {
        this.isSidebarOpen = newValue
      },
    },
    isSidebarOpen: {
      handler(newValue) {
        this.$emit(newValue ? 'sidebar-show' : 'sidebar-hide')
      },
    },
  },
  async mounted() {
    const photos = this.currentUser.userPhotoDTOs

    if (photos && photos.length > 0) {
      this.avatarLink = `https://${baseUrl(null)}${
        photos[photos.length - 1].imagePath
      }`
    }

    this.hasActiveTransformationSession = await transformationSession.hasActiveSession()
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog
    },
    ...mapActions({
      getUser: 'users/getUser',
    }),
    handleSearchTextEntered() {
      this.$emit('search-text-update', this.searchText)
    },
    activateSearch() {
      this.$emit('search-activate')
      this.$nextTick(this.$refs.searchInput.focus)
    },
    addNewQuote() {
      this.$router.push({ name: 'quotes.add' })
    },
    async logOut() {
      await this.$store.dispatch('auth/logOut')
    },
    async backToAdmin() {
      await transformationSession.stop()
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.search-ico-cont {
  display: flex;
  justify-content: center;

  .search-icon-top {
    font-size: 24px;
    color: $primary;
  }
}

.add-circle {
  font-size: 24px;
  color: $primary !important;
}

.the-topbar::v-deep {
  z-index: 10;
  padding: 8px 14px;

  .v-toolbar__content {
    padding: 0 18px;
    overflow: hidden;

    .nav-sidebar-toggle {
      margin-right: 14px;
      margin-left: 0;
    }
  }
}

.tab-container-top {
  display: flex;

  &.map {
    margin-left: 76px;
  }

  &.list {
    margin-left: 58px;
  }

  .toggle-on {
    color: $white;
    background-color: $primary;
  }

  .toggle-off {
    color: $gray-base;
    background-color: $white;
  }
}

.tab-container-top > * {
  width: 75px;
  line-height: 3.5;
  text-align: center;
  cursor: pointer;
}

.active-session {
  border: 4px $red solid;
}

@media only screen and (max-width: 959px) {
  .the-topbar::v-deep {
    .v-toolbar__content {
      padding: 0 10px;
    }
  }
}

::v-deep .v-avatar img {
  object-fit: cover;
}

::v-deep i.v-icon.icon.icon-my-account.theme--light {
  font-size: 32px;
}
</style>
