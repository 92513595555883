var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"cr-modal"},[_c('v-card-title',[_c('h2',[_vm._v("Transfer Call")])]),_c('v-card-text',[_c('v-form',{ref:"transfer-call-form"},[_c('p',[_vm._v(" If you are creating a Lead, please make sure the lead is saved prior to transfering the call. ")]),_c('CRSelect',{attrs:{"id":"call-center-transfer-call-transfer-to-queue-select","items":_vm.transferQueueOptions,"item-text":"label","item-value":"name","label":"Transfer to Queue","placeholder":"Please select the queue to transfer to"},on:{"change":_vm.resetAgentSelect},model:{value:(_vm.transferToQueue),callback:function ($$v) {_vm.transferToQueue=$$v},expression:"transferToQueue"}}),_c('label',[_vm._v("Transfer to Agent")]),_c('v-select',{attrs:{"id":"call-center-transfer-call-transfer-to-agent-select","flat":"","solo":"","items":_vm.transferAgentOptions,"item-value":"worker_sid","item-text":"full_name","no-data-text":"No agents available","placeholder":"Please select the person to transfer to","append-icon":"keyboard_arrow_down"},on:{"change":_vm.resetQueueSelect},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"status-dot",class:{
              'status-dot--available': item.available,
              'status-dot--other':
                !item.available &&
                ['Standby', 'Direct Only'].includes(item.activity),
              'status-dot--unavailable':
                !item.available &&
                !['Standby', 'Direct Only'].includes(item.activity),
            }}),_vm._v(" "+_vm._s(item.full_name)+" - "+_vm._s(item.activity ? item.activity : item.available ? 'Available' : 'Unavailable')+" ")]}}]),model:{value:(_vm.transferToAgent),callback:function ($$v) {_vm.transferToAgent=$$v},expression:"transferToAgent"}})],1)],1),_c('v-card-actions',[_c('v-layout',{attrs:{"justify-end":""}},[_c('div',{staticClass:"button-cont"},[_c('div',{staticStyle:{"margin-right":"-1.5%"}},[_c('v-btn',{attrs:{"id":"call-center-transfer-call-cancel-button","color":"primary","outline":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"id":"call-center-transfer-call-transfer-button","color":"primary","disabled":_vm.isTransferButtonDisabled},on:{"click":_vm.transfer}},[_vm._v(" Transfer ")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }