import { reservationViewPath } from '@/utils/reservationPathBuilder'

const companiesColumn = {
  headers: [
    {
      text: '#',
      align: 'left',
      sortable: true,
      value: 'companyId',
    },
    { text: 'Company Name', value: 'companyName', sortable: true },
    { text: 'Company Email', value: 'companyEmail', sortable: true },
    { text: 'Phone', value: 'companyPhone', sortable: true },
    { text: 'Actions', value: null, sortable: false },
  ],
  mappings: {
    companyId: 'companyId',
    companyName: 'name',
    companyEmail: 'email',
    companyPhone: 'phone',
  },
  pathTarget: 'companyId',
  path(id) {
    return `/companies/view/${id}`
  },
}
const affiliatesColumn = { headers: [], mappings: {} }

const customersColumn = {
  headers: [
    {
      text: '#',
      align: 'left',
      sortable: true,
      value: 'userId',
    },
    { text: 'Customer Name', value: 'customerName', sortable: true },
    { text: 'Customer Email', value: 'email', sortable: true },
    { text: 'Customer Phone', value: 'phone', sortable: true },
    { text: 'Actions', value: null, sortable: false },
  ],
  mappings: {
    userId: 'userId',
    customerName: ['firstName', 'lastName'],
    customerEmail: 'email',
    customerPhone: 'phone'
  },
  pathTarget: 'userId',
  path(id) {
    return `/customers/${id}/details`
  },
}


const quotesColumn = {
  headers: [
    {
      text: '#',
      align: 'left',
      sortable: true,
      value: 'quoteId',
    },
    { text: 'Customer Name', value: 'customerName', sortable: true },
    { text: 'Customer Email', value: 'customerEmail', sortable: true },
    { text: 'Quoted By', value: 'createdBy', sortable: true },
    { text: 'Actions', value: null, sortable: false },
  ],
  mappings: {
    quoteId: 'quoteId',
    customerName: ['customer/firstName', 'customer/lastName'],
    customerEmail: 'customer/email',
    createdBy: ['createdBy/firstName', 'createdBy/lastName'],
  },
  pathTarget: 'quoteId',
  path(id) {
    return `/quotes/view/${id}`
  },
}

const reservationsColumn = {
  headers: [
    { text: '#', align: 'left', sortable: true, value: 'managedId' },
    { text: 'Status', value: 'reservationStatus', sortable: true },
    { text: 'Customer Name', value: 'customerName', sortable: true },
    { text: 'Customer Email', value: 'customerEmail', sortable: true },
    { text: 'Customer Phone', value: 'customerPhone', sortable: true },
    { text: 'Actions', value: null, sortable: false },
  ],
  format: {
    reservationStatus: (string) => {
      return string.replace(/^./, (str) => str.toUpperCase())
    },
  },
  mappings: {
    managedId: 'managedId',
    reservationStatus: 'reservationStatus',
    customerName: ['trip/customer/firstName', 'trip/customer/lastName'],
    customerEmail: 'trip/customer/email',
    customerPhone: 'trip/customer/phone',
  },
  pathTarget: 'managedId',
  path(managedId) {
    return `/reservations/${managedId}`
  },
}
const referralsColumn = {
  headers: [
    { text: '#', align: 'left', sortable: true, value: 'managedId' },
    { text: 'Company Name', value: 'companyName', sortable: true },
    { text: 'Company Email', value: 'companyEmail', sortable: true },
    { text: 'Phone', value: 'companyPhone', sortable: true },
    { text: 'Actions', value: null, sortable: false },
  ],
  mappings: {
    managedId: 'referralReservation/managedId',
    companyName: 'reservation/company/name',
    companyEmail: 'reservation/company/email',
    companyPhone: 'reservation/company/phone',
  },
  pathTarget: ['referralReservation'],
  path(referralReservation) {
    return `/reservations/${referralReservation.reservationId}`
  },
}

const leadsColumn = {
  headers: [
    { text: '#', align: 'left', sortable: true, value: 'leadId' },
    { text: 'Lead Name', value: 'leadName', sortable: true },
    { text: 'Phone', value: 'phone', sortable: true },
    { text: 'Created By', value: 'createdByName', sortable: true },
    { text: 'Actions', value: null, sortable: false },
  ],
  mappings: {
    leadId: 'leadId',
    leadName: ['firstName', 'lastName'],
    phone: 'phone',
    createdByName: ['createdBy/firstName', 'createdBy/lastName'],
  },
  pathTarget: ['leadId'],
  path(leadId) {
    return `/leads/view/${leadId}`
  },
}

export const searchColumns = {
  companies: companiesColumn,
  quotes: quotesColumn,
  customers: customersColumn,
  affiliates: affiliatesColumn,
  reservations: reservationsColumn,
  referrals: referralsColumn,
  leads: leadsColumn,
}
