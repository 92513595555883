import { baseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

export default {
  searchTables(params: { term: string, page: number, perPage: number, paginationObject: string, companyId: number, userId: number, skip: number, isSDR: boolean}): Promise<AxiosResponse<any[]>> {
    const {
      term = '',
      userId,
      skip = 0,
      companyId,
      paginationObject,
      isSDR,
    } = params

    const host = baseUrl()
    let url = ''
    if (isSDR) {
      url = `https://${host}/search/search?term=${term}&userId=${userId}&companyId=${companyId}&companies=none&quotes=none&affiliates=none&reservations=none&referrals=none&leads=all&skip=${skip}&limit=5&paginationObject=${paginationObject}`
    } else {
      url = `https://${host}/search/search?term=${term}&userId=${userId}&companyId=${companyId}&companies=company&quotes=company&affiliates=company&reservations=company&referrals=company&leads=all&customers=company&skip=${skip}&limit=5&paginationObject=${paginationObject}`
    }
    return httpService.get(url)
  },
  globalSearch(params: { term: string, page: number, perPage: number, paginationObject: string}): Promise<AxiosResponse<any[]>> {
    const { term, page = 0, perPage = 5, paginationObject = '' } = params
    const query = [
      `skip=${page > 1 ? page * perPage : 0}`,
      `limit=${perPage}`,
      `paginationObject=${paginationObject}`,
    ]
    const url = `https://${baseUrl()}/search/search?term=${term}&${query.join(
      '&'
    )}`
    return httpService.get(url)
  },
}
