import { AxiosResponse } from 'axios'
import { baseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  async hasActiveSession(): Promise<boolean> {
    const host = baseUrl()
    const url = `https://${host}/transformationSession/hasActiveSession`
    const response: AxiosResponse<ApiResult> = await httpService.get(url)
    return response?.data?.successful === true
  },
  async canTransform(params: { payload: number}): Promise<boolean> {
    const host = baseUrl()
    const url = `https://${host}/transformationSession/validateTransformation`
    const response: AxiosResponse<ApiResult> = await httpService
      .post(url, params.payload)
      .catch((e) => e.response)
    return response?.data?.successful === true
  },
  start(params: { payload: number}): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/transformationSession/start`
    return httpService.post(url, params.payload).catch((e) => e.response)
  },
  stop(): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/transformationSession/stop`
    return httpService.get(url)
  },
}
