import { render, staticRenderFns } from "./FlexContainer.vue?vue&type=template&id=184af114&scoped=true"
import script from "./FlexContainer.vue?vue&type=script&lang=js"
export * from "./FlexContainer.vue?vue&type=script&lang=js"
import style0 from "./FlexContainer.vue?vue&type=style&index=0&id=184af114&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "184af114",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBtn,VDialog})
