<template>
  <div>
    <CRSidebarDialog
      :value="showDialog"
      :persistent="persistent"
      content-class="the-sidebar-dialog"
      @input="closeDialog"
    >
      <template v-if="dialogData && dialogData.title" #title>
        {{ dialogData.title }}
      </template>
      <component
        :is="dialogComponent"
        v-bind="dialogData"
        v-if="dialogComponent"
      />
    </CRSidebarDialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogComponent: { type: Function, default: null },
    dialogData: { type: Object, default: () => ({}) },
    persistent: { type: Boolean },
    showDialog: { type: Boolean },
  },
  methods: {
    closeDialog(e) {
      if (e === false) {
        this.$store.dispatch('app/closeDialog')
      }
    },
  },
}
</script>
