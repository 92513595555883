import { TwilioSyncAccessToken } from '@/models/dto'
import calls from '@/services/calls'
import { SyncClient, SyncStream, SyncMap, SyncMapItem } from 'twilio-sync'

class CallCenterTwilioSyncClient {
  accessToken: TwilioSyncAccessToken
  syncClient: SyncClient

  constructor(accessToken: TwilioSyncAccessToken) {
    this.accessToken = accessToken
    this.syncClient = new SyncClient(this.accessToken.token)

    this.syncClient.on('tokenAboutToExpire', this._refreshToken)

    this.syncClient.on('tokenExpired', this._refreshToken)
  }

  async _refreshToken() {
    const response = await calls.getTwilioSyncAccessToken()
    this.accessToken = response.data
    this.syncClient.updateToken(this.accessToken.token)
  }

  /**
   * The syncClient version of stream handles creating/connecting to a stream
   */
  async getSyncStream():Promise<SyncStream> {
    return this.syncClient.stream(this.accessToken.identity)
  }

  /**
   * Return a reference to the map and an initial pull of all the map items
   * @param mapName
   * @returns
   */
  async getSyncMap(mapName:string):Promise<SyncMapReturn> {
    const syncMap = await this.syncClient.map(mapName)
    let mapItems = await syncMap.getItems()
    let items = mapItems.items

    while (mapItems.hasNextPage) {
      mapItems = await mapItems.nextPage()
      items = items.concat(mapItems.items)
    }

    return {
      initialMapItems: items,
      twilioSyncMap: syncMap
    }
  }
}

export const createCallCenterTwilioSyncClient = async () => {
  const response = await calls.getTwilioSyncAccessToken()
  const accessToken = response.data
  return new CallCenterTwilioSyncClient(accessToken)
}

interface SyncMapReturn {
  initialMapItems: Array<SyncMapItem>,
  twilioSyncMap: SyncMap
}
