<template>
  <div v-if="isFlexUser" class="flex-frame-container">
    <iframe
      v-show="showFlexFrame && flexUrl"
      id="flex"
      ref="flexy"
      allow="microphone"
      class="hide"
      :src="flexUrl"
    ></iframe>
    <div>
      <v-btn
        :loading="loading"
        icon
        large
        :class="buttonClass"
        @click="toggleFlexFrame"
      >
        <img :src="icon" class="action-svg" />
      </v-btn>
    </div>
    <v-dialog v-model="showConfirmDialog" max-width="500px">
      <Confirm
        :header="`Call - ${outgoingCall.firstName} @
            ${outgoingCall.phone ? phoneFormatFilter(outgoingCall.phone) : ''}`"
        :message="`This will initiate a call with ${outgoingCall.firstName} ${outgoingCall.lastName}`"
        :align-buttons-right="true"
        @cancel="cancelOutgoingCall"
        @confirm="confirmOutgoingCall"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { authComputed } from '@/state/helpers'
import { phoneFormatFilter } from '@/utils/phone'
import Confirm from '@/components/Confirm.vue'

export default {
  components: {
    Confirm,
  },
  data() {
    return {
      showConfirmDialog: false,
      loading: false,
      phoneFormatFilter,
      actionMap: {
        worker_activity_update: this.changeStatus,
        task_outgoing: this.startCall,
        task_incoming: this.startCall,
        task_completed: this.endCall,
        task_cancel: this.endCall,
        get_user: this.getUserRequest,
        worker_data: this.setWorkerData,
      },
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      showFlexFrame: 'twilio/showFlexFrame',
      flexCommand: 'twilio/flexCommand',
      flexCommandTrigger: 'twilio/flexCommandTrigger',
      agentStatus: 'twilio/agentStatus',
      isOnCall: 'twilio/isOnCall',
      confirmCall: 'twilio/confirmCall',
      outgoingCallDetail: 'twilio/outgoingCallDetail',
    }),
    outgoingCall() {
      return {
        firstName: this.outgoingCallDetail?.firstName,
        lastName: this.outgoingCallDetail?.lastName,
        phone: this.outgoingCallDetail?.phone,
      }
    },
    icon() {
      const iconMap = {
        Offline: require('@/assets/images/Offline.svg'),
        Available: require('@/assets/images/Available.svg'),
        'Available-on-call': require('@/assets/images/Available - on call.svg'),
        Unavailable: require('@/assets/images/Busy.svg'),
        Break: require('@/assets/images/Busy.svg'),
      }
      const key = this.isOnCall
        ? 'Available-on-call'
        : this.agentStatus || 'Offline'
      return iconMap[key]
    },
    buttonClass() {
      return 'flex-agent-button'
    },
  },
  watch: {
    flexCommandTrigger() {
      this.sendFlexCommand()
    },
    confirmCall(value) {
      this.showConfirmDialog = value
    },
    showConfirmDialog(value) {
      if (!value) {
        this.setConfirmCall(false)
      }
    },
  },
  created() {
    this.flexUrl = this.isProduction()
      ? 'https://flex.twilio.com/violet-dove-8355'
      : 'https://flex.twilio.com/copper-greyhound-9891'
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions('twilio', [
      'setAgentStatus',
      'setIsOnCall',
      'sendAgentInfo',
      'getAgentInfo',
      'clearFlexCommand',
      'setConfirmCall',
      'startOutgoingCall',
      'setFlexWindowDisplay',
    ]),
    init() {
      window.addEventListener('message', this.receiveMessage.bind(this))
    },
    toggleFlexFrame() {
      this.$store.commit('twilio/TOGGLE_FLEX_FRAME')
    },
    receiveMessage(event) {
      const actionName = event?.data?.action_name
      const handler = this.actionMap[actionName]
      if (typeof handler === 'function') {
        handler(event)
      }
    },
    sendFlexCommand() {
      const flexFrame = this.$refs.flexy
      flexFrame.contentWindow.postMessage({ ...this.flexCommand }, '*')
    },
    async changeStatus(event) {
      const status = event?.data?.worker_attributes?.activity_name
      this.setAgentStatus(status)
    },
    getUserRequest() {
      this.sendAgentInfo(this.currentUser.userId)
    },
    startCall() {
      this.setFlexWindowDisplay(true)
      this.setIsOnCall(true)
    },
    endCall() {
      this.setIsOnCall(false)
    },
    cancelOutgoingCall() {
      this.clearFlexCommand()
      this.setConfirmCall(false)
    },
    confirmOutgoingCall() {
      this.startOutgoingCall()
      this.setConfirmCall(false)
    },
    setWorkerData(event) {
      this.changeStatus(event)
    },
    isProduction() {
      const host = window.location.host
      let isProduction
      if (
        host === 'app.coachrail.com' ||
        host === 'checkout.gogocharters.com' ||
        host === 'checkout.shofur.com' ||
        host === 'checkout.nationalbuscharter.com'
      ) {
        isProduction = true
      } else {
        isProduction = false
      }
      return isProduction
    },
  },
}
</script>

<style lang="scss" scoped>
#flex {
  position: fixed;
  right: 24px;
  border: 0px;
  width: 500px;
  height: 600px;
  bottom: 50px;
  z-index: 499;
}

.flex-agent-button {
  position: fixed;
  bottom: 10px;
  right: 16px;
  z-index: 500;
  width: 52px;
  height: 52px;
  background-color: $white;
  border: 1px solid $blue;
  border-radius: 26px;
  &-active {
    background-color: $shofur;
  }
}
</style>
