<template>
  <v-app class="fill-height">
    <EnvironmentIndicator />
    <TheTopbar
      :is-search-open="isSearchOpen"
      @search-deactivate="closeSearch"
      @search-text-update="handleSearchTextUpdate"
      @sidebar-hide="$store.dispatch('app/closeSidebar')"
      @sidebar-show="$store.dispatch('app/openSidebar')"
      @sidebar-toggled="handleSidebarToggled"
    />
    <TheSidebar
      ref="the-sidebar"
      :sidebar="isSidebarOpen"
      :width="$cr.sidebarWidth"
      @sidebar-hide="$store.dispatch('app/closeSidebar')"
      @sidebar-show="$store.dispatch('app/openSidebar')"
      @sidebar-toggled="handleSidebarToggled"
    />
    <TheCallCenter
      v-if="hasCallCenter"
      :sidebar-width="$cr.sidebar.width"
      :is-sidebar-open="isSidebarOpen"
    />
    <TheSearchResults
      v-show="isSearchOpen"
      :search-text="searchText"
      @search-close="closeSearch"
    />
    <TheAlerts />
    <TheDialog
      :dialog-component="dialogComponent"
      :show-dialog="showDialog"
      :dialog-data="dialogData"
    />
    <TheSidebarDialog
      :dialog-component="sidebarDialogComponent"
      :show-dialog="showSidebarDialog"
      :dialog-data="sidebarDialogData"
      :persistent="persistent"
    />
    <v-content column fill-height style="margin: 0">
      <router-view />
    </v-content>
    <div
      v-if="elementIsHighlighted"
      class="app-highlight"
      :style="{
        borderTopWidth: `${Math.trunc(highlightedElementDimensions.top)}px`,
        borderRightWidth: `${Math.trunc(
          highlightedElementDimensions.right + 5
        )}px`,
        borderBottomWidth: `${Math.trunc(
          highlightedElementDimensions.bottom
        )}px`,
      }"
      @click.stop="$store.dispatch('app/clearHighlightedElement')"
    >
      <div class="app-highlight-inner">
        <v-popover
          :open="!!elementIsHighlighted"
          :offset="Math.trunc(highlightedElementDimensions.width + 10)"
          trigger="manual"
          placement="right"
          :style="{
            height: `${Math.trunc(highlightedElementDimensions.height)}px`,
          }"
        >
          <button
            :style="{
              height: `${Math.trunc(highlightedElementDimensions.height)}px`,
            }"
          >
            &nbsp;
          </button>

          <template #popover>
            <h2>{{ highlightedElementText.header }}</h2>
            <p>{{ highlightedElementText.body }}</p>
          </template>
        </v-popover>
      </div>
    </div>
    <FlexContainer />
  </v-app>
</template>

<script>
import TheTopbar from '@/components/TheTopbar.vue'
import TheSidebar from '@/components/TheSidebar.vue'
import TheSearchResults from '@/components/TheSearchResults.vue'
import TheCallCenter from '@/components/TheCallCenter.vue'
import TheAlerts from '@/components/TheAlerts.vue'
import TheDialog from '@/components/TheDialog.vue'
import FlexContainer from '@/components/FlexContainer.vue'

import { authComputed } from '@/state/helpers'
import { mapGetters } from 'vuex'
import TheSidebarDialog from '@/components/TheSidebarDialog.vue'
import EnvironmentIndicator from '@/components/EnvironmentIndicator.vue'

export default {
  metaInfo: {
    title: 'Main',
  },
  components: {
    EnvironmentIndicator,
    TheTopbar,
    TheSidebar,
    TheSearchResults,
    TheCallCenter,
    TheAlerts,
    TheDialog,
    FlexContainer,
    TheSidebarDialog,
  },
  data() {
    return {
      isSearchOpen: false,
      searchText: '',
      queuedAfterTransitionAction: null,
      sidebarElement: null,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters('app', [
      'dialogComponent',
      'sidebarDialogComponent',
      'showDialog',
      'showSidebarDialog',
      'persistent',
      'dialogData',
      'sidebarDialogData',
      'highlightedElementDimensions',
      'highlightedElementText',
      'isSidebarOpen',
    ]),
    elementIsHighlighted() {
      return !!this.highlightedElementDimensions
    },
  },
  watch: {
    '$cr.breakpoint.sidebar'(value) {
      if (value) {
        this.$store.dispatch('app/openSidebar')
      } else {
        this.$store.dispatch('app/closeSidebar')
      }
    },
    // TODO: delete this. Debugging only
    elementIsHighlighted: {
      deep: true,
      handler(isElementHighlighted) {
        if (isElementHighlighted) {
          window.addEventListener('resize', this.clearHighlightedElement)
          return
        }
        window.removeEventListener('resize', this.clearHighlightedElement)
      },
    },
  },
  created() {
    if (this.$cr.breakpoint.smAndDown) {
      this.$store.dispatch('app/closeSidebar')
    }
  },
  mounted() {
    /**
     * This section of code is only because of Vuetify 1.5.
     * Vuetify 2 has an event for when the sidebar transition
     * is finished.
     *
     * Until we upgrade, have to look at the element manually.
     *
     * At the time of writing, I really hope this works. -Jonathan
     */
    this.sidebarElement = this.$refs['the-sidebar']?.$el
    this.sidebarElement.addEventListener(
      'transitionend',
      this.handleSidebarTransitionComplete
    )
  },
  methods: {
    handleSidebarToggled(postToggleActionEvent) {
      if (this.isSidebarOpen) {
        this.$store.dispatch('app/closeSidebar')
      } else {
        this.$store.dispatch('app/openSidebar')
      }
      // This will complete once the transition is finished
      if (postToggleActionEvent) {
        this.queuedAfterTransitionAction = postToggleActionEvent
      }
    },
    clearHighlightedElement() {
      this.$store.dispatch('app/clearHighlightedElement')
    },
    handleSidebarTransitionComplete() {
      if (this.queuedAfterTransitionAction) {
        this.$store.dispatch(
          this.queuedAfterTransitionAction.afterTransitionAction,
          this.queuedAfterTransitionAction.payload
        )
        this.queuedAfterTransitionAction = null
      }
    },
    handleSearchTextUpdate(searchText) {
      this.searchText = searchText
    },
    openSearch() {
      this.isSearchOpen = true
    },
    closeSearch() {
      this.isSearchOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.app-highlight {
  position: fixed;
  border-radius: 0;
  border: 5px solid rgba($gray-base, 0.4);
  box-sizing: border-box;
  top: -0px;
  left: -0px;
  right: -0px;
  bottom: -0px;
  z-index: 9999;
}
.app-highlight::before {
  content: ' ';
  position: absolute;
  top: -0px;
  left: -0px;
  right: -0px;
  bottom: -0px;
  border-radius: 5px;
  border: 2px solid $white;
  z-index: 10;
}
.app-highlight-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
