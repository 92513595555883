<template>
  <v-dialog v-model="dialog" content-class="c-modal" persistent>
    <v-card class="cr-modal">
      <v-card-title>
        <h2>Global Search</h2>
      </v-card-title>
      <v-card-text>
        <CRInput
          id="searchModalInput"
          v-model="searchTerm"
          type="text"
          append-icon="$vuetify.icons.searchLarge"
          :append-cb="performGeneralSearch"
          @change="performGeneralSearch"
        />
        <div v-if="noResults" style="display: flex; justify-content: center">
          <span>No Results for {{ searchTerm }}</span>
        </div>
        <v-tabs
          v-if="searchables.length > 0"
          v-model="model"
          active-class="active-tab"
          hide-slider
        >
          <v-tab
            v-for="i in searchables"
            :key="i"
            :class="activeTab === i.toLowerCase() ? 'active-tab' : ''"
            :href="`#tab-${i}`"
            @click="(evt) => makeTabActive(i.toLowerCase())"
          >
            {{ i }} ({{ count[i.toLowerCase()] }})
          </v-tab>
        </v-tabs>
        <div>
          <v-data-table
            v-if="activeTab"
            :headers="currentHeaders"
            :items="currentSelection"
            hide-actions
          >
            <template #items="props">
              <td
                v-for="(key, index) in Object.keys(props.item).filter(
                  (k) => k !== 'path'
                )"
                :key="`global-search-${activeTab}-${index}`"
              >
                {{ props.item[key] }}
              </td>
              <td>
                <router-link :to="{ path: props.item.path }">
                  <CRIcon>remove_red_eye</CRIcon>
                </router-link>
              </td>
            </template>
          </v-data-table>
        </div>
        <div v-if="activeTab" class="text-xs-center pt-2 spacing">
          <v-pagination
            v-model="pagination.page"
            total-visible="6"
            :length="pages"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-layout justify-end>
          <v-btn class="btn-secondaryaction mr-0 ml-0" @click="close">
            Close
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import op from 'simple-object-path'
import { searchColumns } from '@/components/searchSchema.js'
import search from '@/services/search'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noResults: false,
      searchables: [],
      searchTerm: null,
      model: null,
      pagination: {
        page: 1,
        rowsPerPage: 5,
        totalItems: 0,
      },
      autoSearch: true,
      searchOnce: false,
      count: {
        companies: 0,
        quotes: 0,
        affiliates: 0,
        reservations: 0,
        referrals: 0,
      },
      activeTab: null,
      currentHeaders: [],
      currentSelection: [],
    }
  },
  computed: {
    ...authComputed,
    pages() {
      if (
        this.pagination.rowsPerPage === 0 ||
        this.pagination.totalItems === 0
      ) {
        return 0
      }

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    },
  },
  watch: {
    'pagination.page'(next, prev) {
      if (this.searchOnce) {
        return
      }
      this.paginate()
    },
    searchTerm() {
      if (!this.autoSearch) {
        return
      }
      this.autoSearch = false
      setTimeout(() => {
        this.autoSearch = true
        this.performGeneralSearch()
      }, 2000)
    },
  },
  methods: {
    async performGeneralSearch() {
      const searchResults = await search
        .searchTables({
          term: this.searchTerm,
          userId: this.currentUser.userId,
          skip: 0,
          companyId: this.currentUser.companyId,
          paginationObject: null,
          isSDR: this.isSDR,
        })
        .then((data) => {
          return data?.data
        })
      const res = searchResults.filter((item) => item.count > 0)
      const tab = res[0] ? res[0].name.toLowerCase() : null
      this.activeTab = tab

      this.searchables = searchResults
        .filter((item) => item.count > 0)
        .map((item) => item.name)
      this.currentHeaders = searchColumns[tab] ? searchColumns[tab].headers : []
      this.populateTab(tab, searchResults)
    },
    populateTab(tab, searchResults, searchOnce) {
      if (tab === null) {
        this.noResults = true
        return
      }
      this.noResults = false
      this.pagination.totalItems =
        searchResults.find((result) => result.name.toLowerCase() === tab)
          ?.count || 0
      this.currentSelection = (
        searchResults.find((result) => result.name.toLowerCase() === tab)
          ?.resultList || []
      ).map((res) => {
        const fieldsToDisplay = searchColumns[tab].mappings
        const { pathTarget, path, format } = searchColumns[tab]

        const pathArgs = Array.isArray(pathTarget)
          ? pathTarget.map((target) => {
              return res[target]
            })
          : [res[pathTarget]]

        return Object.keys(fieldsToDisplay).reduce(
          (acc, key) => {
            if (Array.isArray(fieldsToDisplay[key])) {
              acc[key] = fieldsToDisplay[key]
                .map((term) => {
                  return op(res, term)
                })
                .join(' ')
            } else {
              acc[key] =
                format && format[key]
                  ? format[key](op(res, fieldsToDisplay[key]))
                  : op(res, fieldsToDisplay[key])
            }

            return acc
          },
          {
            path: path(...pathArgs),
          }
        )
      })

      searchResults.forEach((prop) => {
        this.count[prop.name.toLowerCase()] = prop.count
      })

      if (searchOnce) {
        this.searchOnce = false
      }
    },
    async makeTabActive(tab) {
      const searchResults = await search
        .searchTables({
          term: this.searchTerm,
          userId: this.currentUser.userId,
          skip: 0,
          companyId: this.currentUser.companyId,
          paginationObject: tab,
          isSDR: this.isSDR,
        })
        .then((data) => {
          return data?.data
        })
      this.activeTab = tab
      this.currentHeaders = searchColumns[tab].headers
      this.pagination.page = 1
      this.searchOnce = true
      this.populateTab(tab, searchResults, this.searchOnce)
    },
    async paginate() {
      const skip = (this.pagination.page - 1) * 5
      const searchResults = await search
        .searchTables({
          term: this.searchTerm,
          userId: this.currentUser.userId,
          skip,
          companyId: this.currentUser.companyId,
          paginationObject: this.activeTab,
          isSDR: this.isSDR,
        })
        .then((data) => {
          return data?.data
        })

      this.populateTab(this.activeTab, searchResults)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .c-modal {
  position: absolute;
  top: 10%;
  display: flex;
  flex-direction: column;
  width: 900px;
  /* padding: 30px 45px 0 45px; */

  /* height: 800px; */
  background-color: $white;
  border-radius: 10px;
}

::v-deep .v-tabs__container {
  border-bottom: 1px solid $gray-even-lighter;
}

.close {
  padding: 0;
  font-size: 25px;
  font-weight: 500;
  line-height: 1;
  color: $white;
  text-shadow: 0 1px 0 $white;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.active-tab {
  border: 1px solid $gray-even-lighter;
  border-bottom-color: $white;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}

.c-modal > * {
  /* margin-bottom: 20px; */
}

/* .spacing {
  margin-bottom: 30px;
} */

::v-deep tbody > tr:nth-child(odd) {
  background-color: $blue-light;
}

::v-deep th.column.sortable.active {
  background-color: transparent !important;
}

::v-deep td i.v-icon.material-icons.theme--light {
  color: $primary;
  cursor: pointer;
}

::v-deep .cr-icon.icon.icon-search.theme--light {
  font-size: 18px;
  color: $primary;
  cursor: pointer;
}

::v-deep button.v-pagination__item {
  box-shadow: none;
}

::v-deep button.v-pagination__navigation {
  box-shadow: none;
}
</style>
