<template>
  <v-card class="elevation-0">
    <v-card-actions>
      <v-btn icon @click="$emit('search-close')">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-title>
      <h4>Search Results</h4>
    </v-card-title>

    <v-layout row wrap>
      <v-flex xs12>
        <v-tabs v-model="selectedTab">
          <v-tab
            v-for="(tab, tabIndex) in tabs"
            :key="tabIndex"
            @click="() => setSelectedTab(tabIndex, true)"
          >
            {{ tab.name }} ({{ tab.count }})
          </v-tab>
        </v-tabs>
        <DataTable
          v-if="selectedTabDataLength"
          :reset-page="resetPage"
          v-bind="tableProps"
        />
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import SearchAction from '@/components/SearchAction.vue'
import search from '@/services/search'
import { phoneFormatFilter } from '@/utils/phone'

const columns = {
  Quotes: [
    {
      _t_id: 'cabd4cdc69eb4bc1b19050f5ab671a91',
      prop: 'quoteId',
      text: 'ID',
      type: 'number',
    },
    {
      _t_id: 'b2e9785d37f84a02a6d8cf85205c79c5',
      prop: ['customer/firstName', 'customer/lastName'],
      text: 'Customer Name',
      detail: false,
    },
    {
      _t_id: 'c9de3c4fefa2441d82bf0489af420238',
      prop: 'customer/email',
      text: 'Customer Email',
      detail: false,
    },
    {
      _t_id: '240c1cbc9d88405096aed68f7894aafe',
      prop: ['createdBy/firstName', 'createdBy/lastName'],
      text: 'Created By',
      detail: false,
    },
    {
      _t_id: '581789987e494a87ac5668fae8ff058e',
      prop: '/',
      text: 'Action',
      action: 'view-quote',
      component: SearchAction,
      detail: false,
    },
  ],
  Reservations: [
    {
      _t_id: '1b42280781bd423084643ba48e80c682',
      prop: 'managedId',
      text: 'ID',
      type: 'text',
      filterType: 'contains',
    },
    {
      _t_id: '09cf760cb58642f793497f034ca65a72',
      prop: ['trip/customer/firstName', 'trip/customer/lastName'],
      text: 'Customer Name',
      detail: false,
    },
    {
      _t_id: 'f52904540fe4477a87eb34cf0107311b',
      prop: 'trip/customer/email',
      text: 'Customer Email',
      detail: false,
    },
    {
      _t_id: 'f6fc23d0bc6d408a8bbddfeae2ff850e',
      prop: 'trip/customer/phone',
      text: 'Customer Phone',
      computedText: (item) => phoneFormatFilter(item),
      detail: false,
    },
    {
      _t_id: 'c5c8acd503904d6cb9e5ebe2c667f585',
      prop: '/',
      text: 'Action',
      action: 'view-reservation',
      component: SearchAction,
      detail: false,
    },
  ],
  Customers: [
    {
      _t_id: '73fd077a432747438ff04e18dfecc104',
      prop: 'userId',
      text: 'ID',
      type: 'number',
    },
    {
      _t_id: 'c0b4ee9d824c499c82537271c0ae133b',
      prop: ['firstName', 'lastName'],
      text: 'Customer Name',
      detail: false,
    },
    {
      _t_id: 'f78f6164993f4d7a923694b5fff7599d',
      prop: 'email',
      text: 'Customer Email',
      detail: false,
    },
    {
      _t_id: '70eac540e9f9495dac6318a8566542c0',
      prop: 'phone',
      text: 'Phone Number',
      detail: false,
    },
    {
      _t_id: 'f1d30076b8de434085820cccd4ea94fa',
      prop: '/',
      text: 'Action',
      action: 'view-quote',
      component: SearchAction,
      detail: false,
    },
  ],
}
export default {
  components: {
    DataTable,
  },
  props: {
    searchText: {
      type: String,
      default: () => undefined,
    },
  },
  data() {
    return {
      debounce: undefined,
      tabs: [],
      selectedTab: 0,
      resetPage: false,
      tableProps: {
        total: this.selectedTabDataLength,
        tableId: 'search_results',
        currentPage: 0,
        perPage: 5,
        list: this.selectedTabData,
        changePage: this.changePage,
        isDetailed: false,
        loading: false,
        isAdmin: false,
        columns: this.selectedTabColumns,
        rowsPerPageItems: [5, 10],
      },
    }
  },
  computed: {
    selectedTabDataLength() {
      const resultList = this.tabs?.[this.selectedTab]?.resultList || []
      return resultList.length
    },
    selectedTabColumns() {
      return this.tabs?.[this.selectedTab]?.columns || []
    },
    selectedTabData() {
      return this.tabs?.[this.selectedTab]?.resultList || []
    },
  },
  watch: {
    searchText(/* text */) {
      this.initSearch(true)
    },
  },
  methods: {
    initSearch(reset) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      this.debounce = setTimeout(() => this.performSearch(reset), 500)
    },
    changePage(pagination) {
      this.page = pagination.page
      this.rowsPerPage = pagination.rowsPerPage
      this.initSearch(false)
    },
    setSelectedTab(tabIndex, fromNav) {
      this.selectedTab = tabIndex
      this.paginationObject = this.tabs[tabIndex].name
      this.page = 1
      if (fromNav) {
        this.resetPage = true
        this.$nextTick(() => {
          this.resetPage = false
        })
        return this.performSearch()
      }
      this.tableProps.columns = this.tabs[tabIndex].columns
      this.tableProps.list = this.tabs[tabIndex].resultList
      this.tableProps.total = this.tabs[tabIndex].count
    },
    async performSearch(reset) {
      const resultsData = await search.globalSearch({
        term: this.searchText,
        paginationObject: this.paginationObject,
        page: this.page,
        perPage: this.rowsPerPage,
      })
      const resultsList = resultsData.data || []
      this.tabs = resultsList
        .filter((tab) => tab.count)
        .map((tab) => {
          tab.columns = columns[tab.name]
          return tab
        })

      if (reset) {
        this.setSelectedTab(0)
      } else {
        this.setSelectedTab(this.selectedTab || 0)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card {
  position: fixed;
  top: 48px;
  z-index: 10;
  width: 100%;
}
</style>
