<template>
  <v-navigation-drawer
    id="the-side-bar"
    v-model="showSidebar"
    class="crBlue elevation-0 the-side-bar"
    dark
    fixed
    :temporary="!$cr.breakpoint.sidebar"
    :stateless="$cr.breakpoint.sidebar"
    :app="$cr.breakpoint.sidebar"
    clipped
    body-2
    :width="width"
  >
    <v-layout align-center style="margin: 15px 12px">
      <v-btn
        flat
        icon
        class="side-sidebar-toggle mr-1"
        @click="$emit('sidebar-toggled')"
      >
        <CRIcon class="pb-0" view-box="0 0 25 25" :width="25" :height="25">
          menu
        </CRIcon>
      </v-btn>
      <router-link :to="{ name: 'home' }" replace class="nav-logo">
        <img
          src="@/assets/images/logo-coachrail-light.svg"
          style="display: block; width: 130px; margin: 13px auto"
        />
      </router-link>
    </v-layout>
    <v-list>
      <template v-for="(item, itemIndex) in menu">
        <template v-if="!menuItemIsDisabled(item) && userPermissionsMatch(item) && menuItemsMask.includes(item.key)">
          <v-list-group
            v-if="item.items"
            :id="`the-sidebar-navigation-${toKebab(item.title)}`"
            :key="item.key || `navigation-item-${itemIndex}`"
            :ref="item.key || `navigation-item-${itemIndex}`"
            v-model="item.active"
            active-class="this-is-just-here-to-prevent-hover-color"
            no-action
            append-icon=""
          >
            <template v-if="item.items" #activator>
              <v-list-tile :id="item.title" :key="item.key || item.title">
                <v-list-tile-action class="tile-action">
                  <CRIcon
                    :class="item.iconClass"
                    :view-box="item.viewBox || undefined"
                    :icon-name="item.iconName"
                  >
                    {{ item.icon }}
                  </CRIcon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>

            <template v-for="(subItem, subItemIndex) in item.items">
              <template v-if="!menuItemIsDisabled(subItem) && userPermissionsMatch(subItem)">
                <v-list-tile
                  v-if="subItem.to"
                  :id="`the-sidebar-navigation-${toKebab(item.title)}-${toKebab(
                    subItem.title
                  )}`"
                  :key="
                    subItem.key ||
                    `navigation-item-${itemIndex}-subitem-${subItemIndex}`
                  "
                  :ref="
                    subItem.key ||
                    `navigation-item-${itemIndex}-subitem-${subItemIndex}`
                  "
                  :to="subItem.to"
                  :class="{
                    'background-blue-darker':
                      routeCategoryName === spliceRouteName(subItem.to.name),
                  }"
                >
                  <v-list-tile-content>
                    <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </template>
          </v-list-group>

          <v-list-tile
            v-else-if="item.to"
            :id="`the-sidebar-navigation-${toKebab(item.title)}`"
            :key="item.key || `navigation-item-${itemIndex}`"
            :ref="item.key || `navigation-item-${itemIndex}`"
            v-model="item.active"
            :prepend-icon="item.icon"
            :to="item.to"
            :class="{
              'background-blue-dark':
                routeCategoryName === spliceRouteName(item.to.name),
            }"
          >
            <v-list-tile-action class="tile-action">
              <CRIcon
                :class="item.iconClass"
                :view-box="item.viewBox || undefined"
                :icon-name="item.iconName"
              >
                {{ item.icon }}
              </CRIcon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>
                {{ item.title }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { authComputed } from '@/state/helpers'
import { toKebab } from '@/utils/string'
import CRIcon from "@/cr/components/CRIcon.vue";

export default {
  components: { CRIcon },
  props: {
    sidebar: {
      type: Boolean,
    },
    width: {
      type: Number,
      default: 230,
    },
  },
  data() {
    return {
      // NOTE:
      // This is a permission driven menu.
      // If user has any of the permissions in the permission attr.
      // then the button will show up. Incrementally move buttons
      // over to this method from the role oriented menus.
      menu: [
        {
          title: 'Quotes',
          icon: 'quotes',
          iconClass: 'icon-quotes',
          iconName: 'quotes',
          viewBox: '0 0 32 32',
          to: { name: 'quotes' },
          key: 'nav-quotes',
        },
        {
          title: 'Reservations',
          icon: 'referral',
          iconName: 'referral',
          to: { name: 'reservations' },
          key: 'nav-reservations',
        },
        {
          title: 'Lead Feed',
          icon: 'lead_feed',
          iconName: 'lead-feed',
          to: { name: 'leadfeed' },
          key: 'nav-lead-feed',
        },
        {
          title: 'Trip Monitoring',
          icon: 'calendar_view_month',
          iconName: 'calendar-view-month',
          viewBox: '0 0 24 24',
          to: { name: 'trip-monitoring' },
          key: 'nav-trip-monitoring',
          permissions: ['canViewWindowWatching'],
        },
        {
          title: 'Tickets',
          icon: 'task',
          iconName: 'task',
          viewBox: '0 0 24 24',
          to: { name: 'tickets' },
          key: 'nav-tickets',
        },
        {
          title: 'Operators',
          icon: 'affiliate_management',
          iconName: 'affiliate_management',
          key: 'nav-operators',
          items: [
            {
              title: 'Map',
              to: { name: 'affiliates-map', params: { mode: 'map' } },
            },
            {
              title: 'List',
              to: { name: 'affiliates-list', params: { mode: 'list' } },
            },
            {
              title: 'Availability',
              to: { name: 'affiliates-availability' },
              disabledParameter: 'availabilityDisabled',
            },
            {
              title: 'Vehicle Reviews',
              to: { name: 'affiliates-vehicleReviews' },
            },
          ],
        },
        {
          title: 'Accounts',
          icon: 'my_company',
          iconName: 'my-company',
          to: { name: 'customer-accounts' },
          key: 'nav-accounts',
        },
        {
          title: 'Customers',
          icon: 'customers',
          iconName: 'customers',
          to: { name: 'customers' },
          key: 'nav-customers',
          permissions: [
              'canViewCompaniesCustomers',
              'canViewInvitedCustomers'
          ],
        },

        {
          title: 'Companies',
          icon: 'my_company',
          iconName: 'my-company',
          to: { name: 'companies' },
          key: 'nav-companies',
        },
        {
          title: 'Users',
          icon: 'customers',
          iconName: 'customers',
          to: { name: 'users' },
          key: 'nav-users',
        },
        {
          title: 'Events',
          icon: 'calendar',
          iconName: 'calendar',
          to: { name: 'events' },
          key: 'nav-events',
        },
        {
          title: 'Accounting',
          icon: 'accounting',
          iconName: 'accounting',
          iconClass: 'icon-accounting',
          key: 'nav-accounting',
          items: [
            {
              title: 'Client Payments',
              to: { name: 'client-payments' },
            },
            {
              title: 'Provider Payments',
              to: { name: 'provider-payments' },
            },
          ],
        },
        {
          title: 'Admin',
          icon: 'admin',
          iconName: 'admin',
          key: 'nav-admin',
          items: [
            {
              title: 'CharterUp Operators',
              to: { name: 'charterup-operators' },
            },
            {
              title: 'Classifications',
              to: { name: 'classifications' },
            },
            {
              title: 'Client Bus Routes',
              to: { name: 'charterup-on-demand-bus-routes' },
            },
            {
              title: 'Credits',
              to: { name: 'credits' },
            },
            {
              title: 'Payments',
              to: { name: 'payments' },
            },
            {
              title: 'Pricing Map',
              to: { name: 'pricing-map' },
            },
            {
              title: 'Metrics Map',
              to: { name: 'metrics-map' },
            },
            {
              title: 'Receipts',
              to: { name: 'receipts' },
            },
            {
              title: 'Referrals',
              to: { name: 'admin-referrals' },
            },
            {
              title: 'Calls',
              to: { name: 'calls' },
            },
            {
              title: 'Market Rate Audits',
              to: { name: 'market-rate-audits' },
            },
            {
              title: 'Market Rate Reviews',
              to: { name: 'market-rate-reviews' },
            },
            {
              title: 'Web Forms',
              to: { name: 'web-forms' },
            },
            {
              title: 'Known Addresses',
              to: { name: 'known-addresses' },
              permissions: [
                'canCreateKnownAddress',
              ],
            }
          ],
        },
        {
          title: 'CharterUP',
          icon: 'charter_up_c',
          iconName: 'charter-up',
          key: 'nav-my-company',
          viewBox: '0 0 24 24',
          items: [
            {
              title: 'General',
              to: { name: 'my-company' },
              key: 'nav-my-company-general',
            },
            {
              title: 'Users',
              to: { name: 'users' },
            },
            {
              title: 'Markups',
              to: { name: 'markups' },
            },
            {
              title: 'Minimums',
              brokerOnly: true,
              to: { name: 'minimums' },
            },
            {
              title: 'Lead Sources',
              brokerOnly: true,
              to: { name: 'lead-sources' },
            },
            {
              title: 'Terms',
              to: { name: 'company-terms' },
              key: 'nav-my-company-terms',
            },
            {
              title: 'Referral Terms',
              brokerOnly: true,
              to: { name: 'company-referral-terms' },
            },
            {
              title: 'Industry',
              to: { name: 'industries' },
            },
            {
              title: 'Markets',
              brokerOnly: true,
              to: { name: 'market-rates' },
            },
            {
              title: 'Checkout Pages',
              brokerOnly: true,
              to: { name: 'checkout-pages' },
            },
          ],
        },
        {
          title: 'Calls',
          icon: 'phone',
          viewBox: '0 0 24 24',
          iconName: 'calls',
          to: { name: 'calls' },
          key: 'nav-calls',
        },
        {
          title: 'Leads',
          icon: 'quotes',
          iconName: 'leads',
          to: { name: 'leads' },
          key: 'nav-leads',
          permissions: [
            'canViewLeadsCreatedByUser',
            'canViewLeadsCreatedByCompany',
            'canEditLeads',
            'canCreateLeads',
          ],
        },
        {
          title: 'Brands',
          icon: 'my_company',
          iconName: 'brands',
          to: { name: 'brands' },
          key: 'nav-brands',
          permissions: ['canViewBrands'],
        },
        {
          title: 'Phone Numbers',
          icon: 'phone',
          viewBox: '0 0 24 24',
          to: { name: 'phone-numbers' },
          key: 'nav-phone',
          permissions: ['canViewPhoneNumbers'],
        },
        {
          title: 'Reviews',
          icon: 'half_star',
          viewBox: '0 0 24 24',
          key: 'nav-reviews',
          items: [
            {
              title: 'Ratings',
              to: { name: 'ratings' },
            },
            {
              title: 'NPS',
              to: { name: 'nps' },
            },
          ],
          permissions: ['canViewReviews'],
        },
        {
          title: 'Chats',
          icon: 'chat',
          viewBox: '0 0 24 24',
          to: { name: 'chats' },
          key: 'nav-chats',
          permissions: ['canViewChats'],
        },
      ],
    }
  },
  computed: {
    ...mapGetters('app', ['highlightedElementRef']),
    ...authComputed,
    showSidebar: {
      get() {
        return this.sidebar
      },
      set(value) {
        this.$store.commit('dashboard/setSideBar', this.showSidebar)
        this.$emit(value ? 'sidebar-show' : 'sidebar-hide')
      },
    },
    routeCategoryName() {
      let name = this.$route.name?.split('.')[0]
      if (name === 'reservation-detail') {
        name = 'reservations'
      }
      return name
    },
    menuItemsMask() {
      const baseMenuItems = [
        'nav-leads',
        'nav-brands',
        'nav-phone',
        'nav-reviews',
        'nav-chats',
        'nav-customers',
        'nav-trip-monitoring',
      ]
      if (this.isSDR) {
        return baseMenuItems;
      }

      if (this.isSDRManager) {
        return [
          ...baseMenuItems,
          'nav-calls',
        ];
      }

      if (
          (this.isL1Manager || this.isRA) &&
          (this.isBrokerAdmin || this.isBrokerUser)
      ) {
        return [
          ...baseMenuItems,
          'nav-quotes',
          'nav-reservations',
          'nav-lead-feed',
          'nav-tickets',
          'nav-operators',
          'nav-accounts',
          'nav-events',
          'nav-accounting',
        ];
      }

      if (this.isL1Manager) {
        return [
          ...baseMenuItems,
          'nav-quotes',
          'nav-reservations',
          'nav-lead-feed',
          'nav-calls',
        ];
      }

      if (this.isMmeSales) {
        return [
          ...baseMenuItems,
          'nav-quotes',
          'nav-reservations',
          'nav-accounts',
        ];
      }

      if (this.isRA) {
        return [
          ...baseMenuItems,
          'nav-quotes',
          'nav-reservations',
        ];
      }

      if (this.isBroker) {
        return [
          ...baseMenuItems,
          'nav-quotes',
          'nav-reservations',
          'nav-lead-feed',
          'nav-tickets',
          'nav-operators',
          'nav-accounts',
          'nav-events',
          'nav-accounting',
          ...(this.currentUser?.group?.groupId === 1 ? ['nav-admin', 'nav-my-company'] : []),
        ];
      } else if (this.isSuperAdmin) {
        return [
          ...baseMenuItems,
          'nav-quotes',
          'nav-reservations',
          'nav-companies',
          'nav-users',
        ];
      }

      return [
        ...baseMenuItems,
        'nav-quotes',
        'nav-reservations',
        'nav-lead-feed',
        'nav-tickets',
        'nav-accounts',
        'nav-accounting',
        ...(this.currentUser?.group?.groupId === 1 ? ['nav-admin', 'nav-my-company'] : []),
      ];
    }
  },
  watch: {
    'this.$cr.breakpoint.sidebar'(value) {
      this.app = value
    },
    highlightedElementRef(elementRef) {
      this.highlightNavigationElementByRef(elementRef)
    },
  },
  methods: {
    toKebab,
    async highlightNavigationElementByRef(elementRef) {
      function timeout(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms))
      }
      async function getElementTopDistanceFromWindow(element) {
        let currentElement = element
        let totalDistance = 0
        while (currentElement) {
          totalDistance += currentElement.offsetTop - currentElement.scrollTop
          currentElement = currentElement.offsetParent
        }
        return totalDistance
      }
      if (!elementRef) {
        return
      }
      if (!this.sidebar) {
        // If the sidebar is not showing, we need to show it, and after
        // the transition is finished, resend this event with the
        // updated ref, which should have changed
        this.$store.dispatch('app/clearHighlightedElement')
        this.$emit('sidebar-toggled', {
          afterTransitionAction: 'app/highlightElementByRef',
          payload: { elementRef },
        })
        return
      }
      if (elementRef.includes('nav-my-company')) {
        const myCompanyElement = this.$refs['nav-my-company'][0].$el
        if (!myCompanyElement.classList.contains('v-list__group--active')) {
          myCompanyElement.firstChild.click()
          await timeout(300)
        }
        const theSidebarElement = document.getElementById('the-side-bar')
        theSidebarElement.scrollTop = theSidebarElement.scrollHeight
      }
      const elements = this.$refs[elementRef]
      if (!elements?.length) {
        return
      }
      const element = elements[0].$el
      const clientRect = element.getBoundingClientRect()
      const elementDistanceFromTopOfWindow = await getElementTopDistanceFromWindow(
        element
      )
      this.$store.dispatch('app/showHighlightForElement', {
        elementDimensions: {
          width: clientRect.width,
          height: clientRect.height,
          top: elementDistanceFromTopOfWindow,
          right: window.innerWidth - (window.pageXOffset + clientRect.right),
          bottom:
            window.innerHeight -
            (elementDistanceFromTopOfWindow + clientRect.height),
        },
      })
    },
    menuItemIsDisabled(item) {
      if (item.disabledParameter != null) {
        return this.$store.getters[`systemParameters/${item.disabledParameter}`]
      }
      return false
    },
    userPermissionsMatch(item) {
      if (item.permissions != null) {
        return item.permissions.find((permission) =>
            this.hasPermission(permission)
        )
      }
      return true
    },
    spliceRouteName(routeName) {
      return routeName.split('.')[0]
    },
  },
}
</script>

<style lang="scss" scoped>
.theme--dark.v-list {
  background: inherit;
}

.v-icon {
  padding-bottom: 15%;
}

.v-list__group__header--active .v-list__group__header__prepend-icon .v-icon {
  color: $white;
}

.the-side-bar {
  z-index: 11;

  .side-sidebar-toggle {
    margin-left: 0;
  }
}

.v-navigation-drawer > .v-list::v-deep {
  .v-list__group--active {
    background-color: $blue-sidebar;
  }

  .v-list__group__header {
    font-weight: 600;
    color: $gray-light;

    .v-icon {
      color: $gray-light;
    }
  }

  .v-list__tile {
    font-weight: 600;
    color: $gray-light !important;

    .v-icon {
      color: $gray-light;
    }

    .tile-action {
      margin-top: 0;
    }

    &:hover {
      color: $white;

      .v-icon {
        color: $white;
      }
    }
  }
}
</style>
