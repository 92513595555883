<template>
  <div>
    <v-dialog :value="showDialog" content-class="the-dialog" :persistent="true">
      <component
        :is="dialogComponent"
        v-bind="dialogData"
        v-if="dialogComponent"
      />
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogComponent: { type: Function, default: null },
    dialogData: { type: Object, default: () => ({}) },
    showDialog: { type: Boolean },
  },
}
</script>

<style lang="scss" scoped></style>
