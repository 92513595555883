<template>
  <v-dialog class="cr-modal" value="dialog" width="500" @input="close">
    <v-form>
      <v-card class="cr-modal">
        <v-card-title>
          <h2>Login As Provider</h2>
        </v-card-title>
        <v-card-text>
          <p>
            <CRInput
              id="admin-company-search"
              v-model="selectedCompany"
              :loading="loading"
              label="Company"
              type="autocomplete"
              :items="suggestedCompanies"
              item-text="display"
              item-key="companyId"
              :return-object="true"
              :search-input.sync="searchText"
              :clearable="true"
              clear-icon="replay"
              placeholder="Search for the company"
              browser-autocomplete="off"
              :aria-errormessage="transformationError"
              :error="transformationError"
              :outline="transformationError"
              @input="selectCompany"
              @click:clear="searchText = undefined"
            />
          </p>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <div class="button-cont">
              <div style="margin-right: -1.5%">
                <v-btn
                  id="company-transformation-modal-button-cancel"
                  color="grey darken"
                  outline
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  id="company-transformation-modal-button-login"
                  color="primary"
                  :loading="loading"
                  @click="loginAsProvider"
                >
                  Login
                </v-btn>
              </div>
            </div>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import companies from '@/services/companies'
import transformationSession from '@/services/transformationSession'
import { authComputed } from '@/state/helpers'
import { mapActions } from 'vuex'
import { filter } from '@/utils/filter'

export default {
  props: {
    userId: {
      type: Number,
      default: null,
    },
    pageName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: true,
      suggestedCompanies: [],
      searchText: undefined,
      selectedCompany: undefined,
      loading: false,
      transformationError: false,
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    searchText(value) {
      this.searchCompanies(value, 'watcher')
    },
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    async loginAsProvider() {
      await transformationSession.start({ payload: this.selectedCompany })
      window.location.reload()
    },
    close() {
      this.$emit('close')
    },
    async selectCompany() {
      if (!this.selectedCompany) return
      this.loading = true
      const canTransform = await transformationSession.canTransform({
        payload: this.selectedCompany,
      })
      if (!canTransform) {
        this.transformationError = true
        this.showAlert({
          type: 'error',
          message:
            'You do not have permission to transform to the selected company.',
        })
      } else {
        this.transformationError = false
      }
      this.loading = false
    },
    async searchCompanies(value, source) {
      if (typeof value !== 'string' || value.length === 0) {
        this.transformationError = false
        return
      }

      this.loading = true
      const nameFilterObject = {
        column: {
          _t_id: 'company_name_search_id',
          prop: 'name',
          filterType: 'contains',
          filterAsIs: true,
        },
        value,
      }
      const activeFilterObject = {
        column: {
          _t_id: 'company_active_search_id',
          prop: 'active',
          filterType: 'eq',
          filterAsIs: true,
        },
        value: true,
      }
      const companyFilter = filter()
      const parentFilter = companyFilter.createParent('and')
      companyFilter.add(parentFilter, nameFilterObject)
      companyFilter.add(parentFilter, activeFilterObject)
      if (this.companySearchDebounce) {
        clearTimeout(this.companySearchDebounce)
      }
      this.companySearchDebounce = setTimeout(async () => {
        const params = {
          filters: companyFilter.asQueryParams(),
          pageSize: 5,
        }
        const matchedCompanies = await companies.getCompanies(params)
        const suggestedCompanies = matchedCompanies?.data?.resultList || []

        this.suggestedCompanies = suggestedCompanies.map((s) => ({
          ...s,
          display: `${s.name} - ${s.companyType?.key}`,
        }))
        this.loading = false
      }, 500)
    },
  },
}
</script>
<style lang="scss" scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 500px;
  padding: 4% 8% 4% 8%;
  overflow-y: hidden;
  border-radius: 5px;

  .label-cont {
    display: flex;
    justify-content: space-between;
  }

  .button-cont {
    display: flex;
    justify-content: flex-end;
  }

  label {
    margin: 0;
    font-size: 20px;
    line-height: 1.42857;
  }

  .close {
    float: right;
    padding: 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: $gray-base;
    text-shadow: 0 1px 0 $white;
    cursor: pointer;
    background: transparent;
    border: 0;
    opacity: 0.2;
  }

  .close:hover {
    opacity: 0.4;
  }
}
</style>
